.main-img-module {
  padding: 0 0 15px 0;
  position: relative;

  .img-box {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;

    img {
      object-fit: cover;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      object-position: center 25%;
    }
  }
  
.img-box::before,
.img-box::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  background: linear-gradient(to bottom, transparent, white);
  z-index: 1;
}

  .logo-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: auto;
  }

  .logo-box img {
    width: 100%;
    height: auto;
  }
    // Media query dla średnich ekranów
    @media (min-width: 768px) and (max-width: 1299px) {
      .logo-box {
        top: 30%;
      }
    }
  
    // Media query dla dużych monitorów
    @media (min-width: 1200px) {

    }
  
    // Media query dla telefonów
    @media (max-width: 767px) {
      .logo-box {
        top: 25%;
      }
    }
}