.navbar-module{
  width: 100%;
  color: black;
  position: sticky;
  top:0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.801);
  padding: 0;
  position: sticky;
  .contact-box{
    background-color: rgba(128, 128, 128, 0.726);
    width: 100%;

  }
  .email, .phone, .adres{
    i{
      color: black;
      margin-right: 5px;
    }
    display: flex;
    align-items: baseline;
  }


  .navigation-box{
    margin:10px;
    .col{
      padding: 0 10px 0 10px;
      cursor: pointer;
      h5{
        color: black;
        font-size: 15px;
      }
      a{
        text-decoration: none;
      }
    }
  }
  .text-right{
    padding-right: 0;
  }


  //PODKRESLENIE: hover
  h5{
    position: relative;
    text-decoration: none; 
    color: rgba(128, 128, 128, 0.726); 
    display: inline-block; 
  
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: rgba(128, 128, 128, 0.726); 
      transition: width 0.3s ease-in-out; 
    }
  
    &:hover::after {
      width: 100%; 
    }
  }

}
@media (min-width: 768px) and (max-width: 1199px) {

}

// Media query dla dużych monitorów
@media (min-width: 1200px) {

}

// Media query dla telefonów
@media (max-width: 767px) {
  .navbar-module{
    display: none;
  }
}

