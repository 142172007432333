.footer-module{
  width: 100%;
  padding: 0;
  .txt-row{
    width: 100%;
    background-color: rgb(23,23,23);
    .col{
      color:rgb(162,162,163);
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items:baseline;
      p{
        margin: 8px 0 8px 0;
      }
      a{
        color: rgb(162,162,163);
        text-decoration: none;
      }
    }
  }
  .nav-row{
    width: 100%;
    background-color:rgb(162,162,163);
    p {
      margin: 8px 0 8px 0;
      position: relative;
      text-decoration: none;
      color: #000;
      display: inline-block; // Umożliwia kontrolowanie layoutu linku
    
      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #000;
        transition: width 0.3s ease-in-out; // Animacja "rysowania" podkreślenia
      }
    
      &:hover::after {
        width: 100%; 
      }
    }
    a{
      text-decoration: none;
    }
  } 
    // Media query dla średnich ekranów
    @media (min-width: 768px) and (max-width: 1199px) {

    }
  
    // Media query dla dużych monitorów
    @media (min-width: 1200px) {
  
    }
  
    // Media query dla telefonów
    @media (max-width: 767px) {
      .nav-row{
        display:none;
      }
    }
}

