.main-module{
  p{
    text-align:justify ;
  }
  .phot{
    width: 250px;
    height: auto;

  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // Media query dla średnich ekranów
  @media (min-width: 768px) and (max-width: 1199px) {

  }

  // Media query dla dużych monitorów
  @media (min-width: 1200px) {
    p{
      font-size: larger;
    }
  }

  // Media query dla telefonów
  @media (max-width: 767px) {

  }
}
