.contact-module{
  margin: 50px 10px 50px 10px;
  .info-box{
    background-color: rgba(128, 128, 128, 0.726);
    border-radius: 10px;
    border: 2px solid rgb(80, 80, 80);
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ifont {
      font-size: 2rem; /* Ustaw rozmiar ikony */
      color: #fff; /* Ustaw kolor ikony */
      margin-bottom: 10px; /* Odstęp pomiędzy ikoną a tytułem */
    }
    .txt-box{
      p,h1{
        margin-bottom: 0;
        color: #fff;
        text-align: center;
      }
    }
  }
  iframe{
    border-radius: 5px;
    margin: 0;
    padding: 0;
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    height: 550px;
  }
}