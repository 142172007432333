.guests-amenities-module{
  border-radius: 5px;
  -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  margin: 25px 0 35px 0;
  height: auto;

  .col-12{
    margin: 0;
    padding: 0;
  }
  .list{

    ul{
      display: flex;
      justify-content: center;
      //align-items: center;
      flex-direction: column;
      list-style-type:none;
      li{
        margin-bottom: 2%;
        text-align: left;
      }
      
    }
  }
  .img-box{
    overflow: hidden;
    position: relative;
    height:390px;
    //object-fit: cover;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    img{
      position: absolute;
      width: 100%;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-height: 135%;
      border-bottom-left-radius: 15px; 
    }
  }
    // Media query dla średnich ekranów
    @media (min-width: 768px) and (max-width: 1250px) {
      @media (max-width: 767px) {
        .img-box{
          img{
            top: -10%;
          }
        }
      }
    }
  
    // Media query dla dużych monitorów
    @media (min-width: 1251px) {
        
    }
  
    // Media query dla telefonów
    @media (max-width: 767px) {
      .img-box{
        img{
          top: -40%;
        }
      }
    }
}