.house-module{
  text-align:justify;
  .gallery-module{
    .row{
      .img-box{
        margin-top:3%;
        height: auto;
        width: auto;
        img{
          margin: 5px;
        }
      }
    }   
  }
  .contact-module{
    margin-top: 30px;
  }
  .house-box{
    padding-right: 35px;
  }
  .summary-box{
      height: auto;
      padding-right: 25px;
      border-radius: 5px;
      padding-bottom: 50px;
      background-color: rgb(238,236,225);
      -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
      -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
      box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
      ul{
        margin-top: 10px;
        list-style:circle;
        text-align: justify;
        li{
          //margin-bottom: 2px;
        }
      }
    }  
}